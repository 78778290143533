import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import BlogArticles from '../../components/BlogArticles';
import Footer from '../../components/Footer';
import ContextualCta from '../../components/ContextualCta';
import GetUpdatesForm from '../../components/BlogGetUpdatesForm';
import { PageContext } from '../../context/PageContext';
import { ItemDatoBlogInfo as BlogArticle } from '../../services/interfaces';
import { DatoCmsSourceGraphQlResponse } from '../../types';
import useBlogArticles from '../../hooks/useBlogArticles';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

type BlogData = {
  allDatoCmsBlogArticles: DatoCmsSourceGraphQlResponse<BlogArticle>;
};

type BlogProps = PageProps<BlogData, PageContext>;

const AllBlogPosts: React.SFC<JSX.Element> = (props: BlogProps) => {
  const { pageContext } = props;
  const { locale, pageName } = pageContext;
  const { t } = useTranslation(['blog']);

  const articles = useBlogArticles();
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName}>
        <PageTitle
          subtitle={t('blog.mainHeadline.allPosts.subtitle')}
          title={t('blog.mainHeadline.allPosts.title')}
        />
      </Header>
      <BlogArticles articles={articles} locale={locale} />
      <ContextualCta />
      <GetUpdatesForm />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(seo)|(breadcrumbs)|(contextual-cta)|(blog)|(eyebrow)|(course-list)|(banner)|(application-form)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default AllBlogPosts;
